import React from 'react';
import { Provider } from 'react-redux';
import { OverlayProvider } from '@react-aria/overlays';
import { SSRProvider } from '@react-aria/ssr';
import ThemeProvider from 'workspace-core-theme';
import { MotionConfig } from 'framer-motion';
import ErrorBoundary from '@components/ErrorBoundary';
import { IS_IN_QA_MODE } from '@sharedConstants';
import allRoutes from '../../content/routemap.json';
import userColorTheme from '../../content/color-theme.json';
import makeStore from './store';
import gameConfig from '../../content/gameconfig';

const defaultRoutes = (() => {
  // if our gameConfig contains a profiler, then override and set _that_ to be our initial routing list
  if (allRoutes?.Splitter) {
    return allRoutes.Splitter;
  }
  if (Array.isArray(gameConfig.Stream_Table_Names)) {
    return allRoutes[gameConfig.Stream_Table_Names[0]];
  }
  return allRoutes[gameConfig.Stream_Table_Names];
})();
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // we use the first thing in our constants as a routemap
  const store = makeStore({
    allRoutes: defaultRoutes,
    pooledRoutes: allRoutes?.Pooled || [],
    insightRoutes: allRoutes.stream4 || [],
  });
  // give the window our store for e2e tests
  if (typeof window !== 'undefined' && window.Cypress) {
    window.store = store;
  }

  let TestHelper = <></>;
  if (IS_IN_QA_MODE) {
    TestHelper = React.lazy(() => import('../tests/TestHelperWidget.tsx'));
  }
  return (
    <SSRProvider>
      <Provider store={store}>
        <OverlayProvider style={{ display: 'inline' }}>
          {/* TODO: we only support colors for now */}
          <ThemeProvider
            customColors={userColorTheme}
            funFont={gameConfig.Title_Font}
            bodyFont={gameConfig.Body_Font}
          >
            <MotionConfig
              reducedMotion={gameConfig.Reduce_Motion ? 'always' : 'user'}
            >
              {IS_IN_QA_MODE && <TestHelper />}
              <ErrorBoundary>{element}</ErrorBoundary>
            </MotionConfig>
          </ThemeProvider>
        </OverlayProvider>
      </Provider>
    </SSRProvider>
  );
};
