import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useVisuallyHidden } from '@react-aria/visually-hidden';
import { useAppSelector } from '@hooks/redux-hooks';
import css from '@styled-system/css';
import { Flex, Label, Box } from 'workspace-core-ui';
import useTranslation from '@hooks/useTranslation';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import gameConfig from '@content/gameconfig';
import AboutTrayButton from './AboutTrayButton';
import ShareModalButton from './ShareModalButton';
import { Points } from '../points/Points';

const HeaderLabel = styled(Label).attrs({
  variant: 'l3',
})`
  cursor: pointer;
`;

const QuestionCounter = () => {
  const { claimIndex, totalClaims } = useAppSelector(s => s.levelling);
  const controls = useAnimation();
  const { t } = useTranslation();

  useEffect(() => {
    controls.start({
      scale: 1,
      transition: {
        type: 'spring',
        velocity: 50,
        stiffness: 700,
        damping: 80,
      },
    });
  }, [claimIndex, controls]);

  return (
    <Flex
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 1,
      }}
    >
      <Label
        as={motion.div}
        animate={controls}
        sx={{
          mr: '3px',
        }}
      >
        {claimIndex}
      </Label>
      <Label variant="l2">
        {t('Claim Score Interstice')} {totalClaims}
      </Label>
    </Flex>
  );
};

const HeaderWrapper = styled(Flex).attrs({
  as: 'header',
})(
  css({
    height: '70px',
    width: '100%',
    p: 4,
    pt: 0,
    position: 'fixed',
    zIndex: 'towerBlock',
  }),
);

const IconLabelContainer = styled(Flex).attrs({ variant: 'l3' })({
  flexDirection: 'row',
});

export function Header(): JSX.Element {
  const [isLevellingUp, setIsLevellingUp] = useState(false);
  const { pointsForLevel, currentLevel, pointsThreshold, totalPoints } =
    useAppSelector(s => s.levelling);
  const { headerType } = useAppSelector(s => s.gameState);
  const { visuallyHiddenProps } = useVisuallyHidden();
  const { t } = useTranslation();
  const shareModalTitle = t('Share Modal Header');
  const progressBarValue = (pointsForLevel / pointsThreshold) * 100;
  const shouldReduceMotion = useCustomReducedMotion();

  const headerElems = (() => {
    const iden = {
      // the about and sound are always shown
      showLabels: false,
      showQuestionCount: false,
      showLevelStar: false,
      showShare: false,
    };
    switch (headerType) {
      case 'intro':
        iden.showLabels = true;
        break;
      case 'full':
        iden.showQuestionCount = true;
        iden.showLevelStar = true;
        break;
      case 'recall':
        iden.showLevelStar = true;
        break;
      case 'shareable':
        iden.showLevelStar = false;
        iden.showQuestionCount = false;
        break;
      case 'minimal':
      default:
        break;
    }

    return iden;
  })();

  return (
    <HeaderWrapper>
      <AnimatePresence initial={false} exitBeforeEnter>
        {headerElems.showLabels ? (
          <Flex
            key="label-icons"
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <IconLabelContainer mr={[2, 4, 4]}>
              <AboutTrayButton
                buttonProps={{
                  mr: 1,
                  id: 'about-tray-btn',
                  ariaLabelledBy: 'about-btn-label',
                }}
              />
              <HeaderLabel id="about-btn-label" for="about-tray-btn">
                {t('About Button')}
              </HeaderLabel>
            </IconLabelContainer>
            {gameConfig.Enable_Sharing && (
              <IconLabelContainer>
                <ShareModalButton
                  loggingTarget="share_modal_button_header"
                  modalTitle={shareModalTitle}
                  buttonProps={{
                    mr: 1,
                    id: 'share-btn',
                    ariaLabelledBy: 'share-btn-label',
                  }}
                />
                <HeaderLabel id="share-btn-label" for="share-btn">
                  {t('Share Modal Header')}
                </HeaderLabel>
              </IconLabelContainer>
            )}
          </Flex>
        ) : (
          <Flex
            key="icons"
            flex={1}
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Flex mr={2}>
              <AboutTrayButton />
              <span {...visuallyHiddenProps}>{t('About Button')}</span>
            </Flex>
            <HeaderLabel>
              <ShareModalButton
                loggingTarget="share_modal_button_header"
                modalTitle={shareModalTitle}
                buttonProps={{ mr: 1, id: 'share-btn' }}
              />
              <span {...visuallyHiddenProps}>{t('Share Modal Header')}</span>
            </HeaderLabel>
          </Flex>
        )}
      </AnimatePresence>
      <Flex justifyContent="center" flex={1}>
        {headerElems.showQuestionCount && <QuestionCounter />}
      </Flex>
      <Flex flex={1} alignItems="center" justifyContent="flex-end">
        {headerElems.showLevelStar && (
          <>
            <Box width="120px" mr="10px">
              <Points />
            </Box>
          </>
        )}
        {headerElems.showShare && (
          <ShareModalButton
            loggingTarget="share_modal_button_header"
            modalTitle={t('Share Modal Header')}
          />
        )}
      </Flex>
    </HeaderWrapper>
  );
}

export default Header;
