import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { parse } from './domain/points';
import { RootState } from '../redux/store';

export type PointsState = {
  points: number;
};

export const loadPoints = createAsyncThunk('points/load', async () => {
  const points = parse(localStorage.getItem('points'));
  console.log(`****POINTS: load ${points.points} from local storage.`);
  return { points: points.points };
});

export const savePoints = createAsyncThunk(
  'points/save',
  async (data: { points: number }, { getState }) => {
    const state = getState() as RootState;
    const updatedPoints = state.points.points + data.points;
    localStorage.setItem(
      'points',
      JSON.stringify({
        points: updatedPoints,
      }),
    );
    console.log(`****POINTS: save ${updatedPoints} to local storage`);
    return { points: updatedPoints };
  },
);

export const pointsSlice = createSlice({
  name: 'points',
  initialState: {
    points: 0,
  },
  extraReducers(builder) {
    builder.addCase(loadPoints.fulfilled, (state, action) => {
      state.points = action.payload.points;
    });
    builder.addCase(savePoints.fulfilled, (state, action) => {
      state.points = action.payload.points;
    });
  },
  reducers: {
    awardPoints: (state, action: PayloadAction<{ points: number }>) => {
      console.log(
        `****POINTS: adding ${action.payload.points} to existings ${state.points}`,
      );
      state.points += action.payload.points;
    },
  },
});

export const { awardPoints } = pointsSlice.actions;

export default pointsSlice.reducer;
