import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import css from '@styled-system/css';
import { animate } from 'framer-motion';
import styled from 'styled-components';
import { Label } from 'workspace-core-ui';
import { loadPoints } from './pointsSlice';

export const useAnimatedCounter = (
  maxValue: number,
  initialValue = 0,
  duration = 1,
) => {
  const [counter, setCounter] = useState<number>(initialValue);

  useEffect(() => {
    const controls = animate(initialValue, maxValue, {
      duration,
      onUpdate(value) {
        setCounter(parseInt(value.toFixed(0), 10));
      },
    });
    return () => controls.stop();
  }, [initialValue, maxValue, duration]);

  return counter;
};

const Count = styled(Label).attrs({ variant: 'l2' })(
  css({
    color: 'text',
    width: '100%',
  }),
);

export const Points = (): JSX.Element => {
  const points = useAppSelector(s => s.points);
  const lastPoints = useRef<number>(points.points);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadPoints());
  }, [dispatch]);

  useEffect(() => {
    console.log(`Current points: ${lastPoints.current}`);
    lastPoints.current = points.points;
  }, [points]);

  const newPoints = useAnimatedCounter(points.points, lastPoints.current, 1);
  console.log(
    `POINTS values: ${newPoints} - ${points.points} - ${lastPoints.current}`,
  );

  return (
    <span>
      <Count>Points: {newPoints}</Count>
    </span>
  );
};
