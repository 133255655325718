import * as z from 'zod';

const pointsSchema = z.object({
  points: z.number(),
});
export type Points = z.infer<typeof pointsSchema>;

export function create(): Points {
  return {
    points: 0,
  };
}

export function parse(blob: string | null): Points {
  if (blob == null) {
    return create();
  }
  const parseResult = pointsSchema.safeParse(JSON.parse(blob));
  if (parseResult.success === false) {
    // TODO: maybe we should be more graceful and try to recover
    throw new Error('Error reading points');
  }
  return parseResult.data;
}
